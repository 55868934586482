<template>
  <div class="flex flex-col-reverse md:flex-row">
    
    <template v-if="loginVisible">
      <div class="md:w-56">

        <form @submit.prevent="onSubmit">
          
          <PInput 
            ref="username" 
            v-model="username" 
            :label="$t('Login.Username')" 
            :disabled="isLoggingIn" 
          />
          
          <PInput 
            class="mt-4" 
            ref="password" 
            v-model="password" 
            :label="$t('Login.Password')" 
            :disabled="isLoggingIn" 
            type="password" 
          />

          <PButton 
            class="mt-8 w-full justify-center" 
            type="submit" 
            color="primary" 
            :loading="isLoggingIn"
          >
            {{ $t("Login.Login") }}
          </PButton>
          
          <PError 
            v-if="loginFailed" 
            :text="$t('Login.LoginFailed')" 
          />

        </form>

        <div class="mt-2 md:mt-8 text-sm flex flex-col items-center space-y-2">
          <router-link class="hover:underline" to="forgot-password">{{ $t("Login.ForgotPassword") }}</router-link>
          <router-link class="hover:underline" to="new-customer">{{ $t("Login.RegisterNewCustomer") }}</router-link>
        </div>
      </div> 

      <div class="messages rte overflow-y-auto mb-10 md:mb-0 md:ml-10">
        <PLoader v-if="isLoadingMessages" />
        <template v-else>
          <div class="bg-orange-100 p-4 text-sm">          
            <div v-for="(message, index) in messages" :key="index">
              <h2 class="font-medium text-base">{{ message.title }}</h2>
              <div class="mt-2" v-html="message.text"></div>
            </div>
          </div>
        </template>
      </div>
    </template>

    <div class="text-sm" v-if="isRedirecting">
      <span v-html="$tk('Login.AlreadyLoggedIn')"></span>
      {{ user.name ? " (" + user.name + ")" : "" }}, 
      <span v-html="$tk('Login.RedirectingToPortal')"></span>      
    </div>

    <div 
      class="text-sm" 
      v-if="isLoadingStatus" 
      v-html="$tk('Common.General.PleaseWait')"
    ></div>

  </div>
</template>

<script>
import http from "@/http"
import { get, filter } from "lodash"
import PError from "@/components/PError.vue"
import TokenService from "@/services/TokenService"
import { getConfig } from "@/config"

const config = getConfig()

export default {
  
  name: "login",  
  
  components: {
    PError,
  },

  data () {
    return {
      username: process.env.VUE_APP_USERNAME || "",
      password: process.env.VUE_APP_PASSWORD || "",
      isUsernameAutoFilled: false,
      isPasswordAutoFilled: false,      
      isLoggingIn: false,
      isRedirecting: false,
      isLoadingStatus: false,
      isLoadingMessages: false,
      loginVisible: false,
      loginFailed: false,      
      status: null,
      messages: [],
      language: ""
    }
  },
  
  computed: {
    user () {
      return get(this.status, "user", null)
    },
    locale () {
      return this.$i18n.locale
    }
  },
  
  methods: {

    onSubmit () {
      this.isLoggingIn = true      
        
      http
        .post("Login", {
          userLoginName: this.username,
          password: this.password,
          anonymous: true
        })
        .then((response) => {                        
          if (config.agreementUserIdRequired && !response.customer.agreementStandard) {
            this.$router.push({ name: "agreement" })
            return;
          } 
          else {

            const returnUrl = get(this.$route, "query.returnUrl", "")

            if (returnUrl) {
              this.$router.push({ path: returnUrl })
            }
            else {
              this.$router.push({ name: "home" }).catch(() => {})
            }

            this.loginFailed = false
          }
        })
        .catch(() => {                    
          this.loginFailed = true
          this.isLoggingIn = false
        })
    },

    async loadMessages (language) {
      
      this.isLoadingMessages = true
      try {
        const messages = await http.get("Messages", { 
          params: { 
            type: "login",
            taskId: "welcome",
            anonymous: true,
            language
          }
        })
        this.messages = filter(messages, m => !!m.text)
      } catch {      
        this.messages = []
      }
      this.isLoadingMessages = false
    }

  },

  async created () {

    // if (this.$route.query.auth) {      
    //   TokenService.setToken(this.$route.query.auth)
    // }

    let language

    try {
      language = await http.get("Language")
    } catch {
      language = this.$i18n.fallbackLocale
    }

    this.$i18n.locale = language

    const token = TokenService.getToken()

    if (token) {
      this.isLoadingStatus = true
      try {
        this.status = await http.get("Status")
      } catch {
        this.status = null  
      }
      this.isLoadingStatus = false
    }

    if (this.user) {
      this.isRedirecting = true
      setTimeout(() => {
        this.$router.push({ name: "app" })
      }, 2000)
    } else {
      this.loginVisible = true
      await this.loadMessages(language)
    }
  }
}
</script>

<style scoped>

.messages {  
  max-height: 140px;
}


@screen md {

  .messages {  
    max-height: 300px;
  }

  .messages {
    width: 400px;
  }
}

</style>