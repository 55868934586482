<template>
  <div class="flex flex-col space-y-4">
    <PSelect      
      label="Land"
      :disabled="isLoadingCountries"
      :items="countries"
      :searchable="true"
      itemValue="id"
      itemText="name"      
      v-if="countrySelectVisible"
      v-model="editCountryId"
    />
    <div class="flex items-start space-x-4 mt-4">
      <div class="w-1/3">
        <PInput
          :label="$tk('Common.Address.PostCode')"
          :disabled="!editCountryId"
          :mask="zipCodeMask"
          :wait="500"
          v-model="editPostCode"
        />
      </div>
      <div class="w-2/3">
        <PInput
          :label="$tk('Common.Address.PostPlace')"          
          :disabled="!editCountryId || !canEditPostPlace"
          v-model="editPostPlace"
        />
      </div>
    </div>
  </div>
</template>

<script>

import http from "@/http"

import {
  find,
  get,
  filter
} from "lodash"

export default {
  
  name: "p-post-code-picker",

  props: {
    countryId: {
      type: String,
      required: false
    },
    postCode: {
      type: String,
      required: false
    },
    countrySelectVisible: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      countries: [],
      editCountryId: "",
      editPostCode: "",
      editPostPlace: "",
      isLoadingCountries: false,
      isLoadingPostPlace: false,
      canEditPostPlace: false
    }
  },

  computed: {   
    zipCodeMask () {
      const country = find(this.countries, c => c.id === this.editCountryId)
      return get(country, "zipCodeMask", "").split("|")
    },    
    postCodeWatchTrigger () {
      return this.countries.length ? this.postCode : ""      
    }
  },

  async created () {
    this.isLoadingCountries = true
    this.countries = filter(await http.get("AddressCountryRegions"), c => c.isAvailable)
    this.editCountryId = get(find(this.countries, c => c.isDefault), "id", "")
    this.isLoadingCountries = false    
  },

  watch: {

    countryId: {
      handler: function (val) {        
        this.editCountryId = val
      }, immediate: true
    },

    editCountryId: function () {      
      this.editPostCode = ""
      this.editPostPlace = ""
    },
    
    postCodeWatchTrigger: function (val) {
      this.editPostCode = val        
    },

    editPostCode: async function (val) {            
      const maskOk = val && find(this.zipCodeMask, m => val.length === m.replace(/\s/g, '').length)      
      if (maskOk) {            
        this.isLoadingPostPlace = true
        const postPlace = await http.get("AddressZipCode", {
          params: {
            countryId: this.editCountryId,
            postalCode: val
          }          
        })
        if (postPlace) {
          this.canEditPostPlace = false
          this.editPostPlace = postPlace.name
        } else {
          this.canEditPostPlace = true
          this.editPostPlace = ""
        }
        this.isLoadingPostPlace = false
      } else {
        this.canEditPostPlace = true
        this.editPostPlace = ""
      }
    },
    
    editPostPlace: {
      handler: function (val) {
        this.$emit("change", {
          postCode: this.editPostCode,
          postPlace: val,
          countryId: this.editCountryId
        })
      }
    }
  },
}

</script>