import http from "@/http"
import axios from "axios"
import { get, filter, forEach } from "lodash"

const CancelToken = axios.CancelToken;

let source;

const api = {
  
  async getOrders(
    method,
    params,
    orderByField, 
    orderByDescending, 
    filters,
    countDetailed
  ) {

    let args = []
    
    if (method === "orders") {
      args.push({ name: "withfees", value: true })
      args.push({ name: "withsort", value: false })
      args.push({ name: "withtempsort", value: false })
      args.push({ name: "withtempsortlines", value: false })
      args.push({ name: "withtempsortlinespivoted", value: false })
      args.push({ name: "withqualitychanges", value: false })
      args.push({ name: "withemployees", value: false })
      args.push({ name: "countdetailed", value: countDetailed })
      args.push({ name: "count", value: 1000 })
    }

    args = args.concat(getParamArgs(params))
    args = args.concat(getFilterArgs(filters))

    // TODO, remove this temporary hack when API is fixed
    if (orderByField === "dateSend") {
      orderByField = "sendon"
    }
    
    if (orderByField) {
      args = args.concat([
        { name: "orderBy[0].field", value: orderByField },
        { name: "orderby[0].descending", value:  orderByDescending }
      ])
    }

    const query = args.map(arg => `${arg.name}=${arg.value}`).join("&")    

    if (source) {         
      source.cancel("Operation canceled due to new request.")
    }

    source = CancelToken.source()
    
    try {
      return await http.get(`/${method}?${query}`, { 
        cancelToken: source.token 
      })
    } catch {
      // TODO: throw if not a Cancel error
      // console.log("getOrders", error, axios.isCancel(error))
    }
  },

  async getOrdersById(locationId, ids) {   
    const query = ids.map(id => `&ids=${id}`).join("")
    return await http.get(`/Orders?locationId=${locationId}${query}`)
  },

  async getExternalOrders(
    params,
    orderByField, 
    orderByDescending, 
    filters
  ) {

    let args = []

    args = args.concat(getParamArgs(params))
    args = args.concat(getFilterArgs(filters))

    const query = args.map(arg => `${arg.name}=${arg.value}`).join("&")

    const orders = await http.get(`/ExternalOrders?${query}`) // ${include}

    if (orderByField) {
      args = args.concat([
        { name: "orderBy[0].field", value: orderByField },
        { name: "orderby[0].descending", value:  orderByDescending }
      ])
    }

    return orders

  }
}

const getParamArgs = function (params) {
  
  let args = []

  Object.keys(params).forEach(key => {
    args.push({ name: key, value: params[key] })
  })

  return args
}

const getFilterArgs = function (filters) {

  let args = []

  if (filters) {
    
    const term = get(filters, "term.value", "")
    if (term) {
      args.push({ name: "term", value: term })
      args.push({ name: "source", value: "sqlorder" })
      args.push({ name: "mode", value: "prefix" })
    }

    const sendLower = get(filters, "sendLower.value", "")
    if (sendLower) {
      args.push({ name: "sendLower", value: sendLower })
    }

    const sendUpper = get(filters, "sendUpper.value", "")
    if (filters.sendUpper && filters.sendUpper.value) {
      args.push({ name: "sendUpper", value: sendUpper })
    }
    
    // statuses
    const statuses = filter(filters.statuses, status => status.value)      
    const statusIds = []
    forEach(statuses, status => {
      if (status.ids) {
        statusIds.push(...status.ids)
      } else {
        statusIds.push(status.id)
      }
    })    
    if (statusIds.length > 0) {   
      args.push({ name: "transactionStatus", value: statusIds.join(",")})     
    }
      
  }

  return args
}

export default api
