<template>
  <div 
    class="p-4 text-sm cursor-pointer hover:bg-orange-200 group"
    :class="{
      'border-b border-dashed border-gray-300': border
    }"
  >
          
    <router-link 
      class="relative block pl-6"
      :to="{ name: routeName, params: routeParams }"
    >
      <div class="absolute left-0 top-0">
        <FontAwesomeIcon :icon="['far', icon]" />
      </div>

      <div class="font-semibold text-green-500 underline group-hover:text-black">{{ alert.title }}</div>              
      <p class="mt-1">{{ alert.text }}</p>

    </router-link>

    <!-- <template v-else>
      <div class="text-xs font-medium">{{ alert.updated | dateAndTime }}</div><br/>
      source: {{ alert.source }}<br/>
      reason: {{ alert.reason }}<br/>
      title: {{ alert.title }}<br/>
      text: {{ alert.text }}<br/>
      sourceId: {{ alert.sourceId }}
    </template>
       -->
      
    
  </div>

</template>

<script>

export default {

  props: {
    alert: {
      type: Object,
      required: true
    },
    border: {
      type: Boolean,
      default: true
    }
  },

  computed: {

    source () {
      return this.alert.source
    },

    icon () {
      return this.source === "Support" ? "life-ring" :
             this.source === "Message" ? "newspaper" : 
             this.source === "Order"   ? "pallet" : 
             this.source === "Invoice" ? "file-invoice" : "exclamation-circle"
    },

    routeName () {
      return this.source === "Support" ? "support.Tickets" :
             this.source === "Message" ? "messages.Message" : 
             this.source === "Order"   ? "orders" : 
             this.source === "Invoice" ? "reports.Invoices" :
             this.source === "Log"     ? "global.Log" : "home"      
    },

    routeParams () {
      
      let params = { id: this.alert.sourceId }

      if (this.alert.source === "Invoice") {
        params.type = "customer"
      }

      return params
    }
  }
}
</script>